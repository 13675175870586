.iframeReport,.iframeReportM {
    width: 100%;
    height: 500px !important;
    border: 2px solid #22e67e !important;
    color: #22e67e;
    box-shadow: 0 1px 6px #22e67e, 0 1px 4px #22e67e;
}
.iframeReport1{
    width: 50%;
    height: 500px !important;
    border: 2px solid #22e67e !important;
    color: #22e67e;
    box-shadow: 0 1px 6px #22e67e, 0 1px 4px #22e67e;
}
.pdfIcon{
    display: none;
}

$breakpoint-desktopfrom: 1007px;
$breakpoint-desktopTo: 1350px;
@media (min-width: $breakpoint-desktopfrom) and (max-width:$breakpoint-desktopTo) {
    .iframeReport{
        display: none;
    }
    .iframeReport1{
        width: 100%;
       height: 85% !important;
    }
    .pdfIcon{
        display: block;
        font-size: 100px;
        color: #1ac5ad;
        display: inline;
    }
    
}

$breakpoint-mobile: 300px;
$breakpoint-mobileTo: 500px;
@media (min-width: $breakpoint-mobile) and (max-width:$breakpoint-mobileTo) {
    .iframeReport{
        display: none;
    }
    .iframeReport1{
        width: 100%;
       height: 85% !important;
    }
    .pdfIcon{
        display: block;
        font-size: 100px;
        color: #1ac5ad;
        display: inline;
    }
}

$breakpoint-mobile: 700px;
$breakpoint-mobileTo: 900px;
@media (min-width: $breakpoint-mobile) and (max-width:$breakpoint-mobileTo) {
    .iframeReport{
        display: none;
    }
    .iframeReport1{
        width: 100%;
       
    }
    .pdfIcon{
        display: block;
        font-size: 100px;
        color: #1ac5ad;
        display: inline;
    }
}