.donateModal{
    .modal-dialog{
        max-width: 80%;
    }
    .okButtonModal{
        background-color: #1ac5ad;
        border: 1px solid #1ac5ad;
    }
    .okButtonModal:hover{
        background-color: #d9f105;
        border: 1px solid #d9f105;
        color: black;
    }
}
$breakpoint-mobile: 300px;
$breakpoint-mobileTo: 500px;
@media (min-width: $breakpoint-mobile) and (max-width:$breakpoint-mobileTo) {
    .testi1{
        img{
            width:90% !important;
        }
    }
   }
   
$breakpoint-desktopfrom: 1007px;
$breakpoint-desktopTo: 1350px;
@media (min-width: $breakpoint-desktopfrom) and (max-width:$breakpoint-desktopTo) {
    .modal-dialog{
        max-width: 90% !important;
        .modal-title{
            font-size: 19px;
        }
    }
}