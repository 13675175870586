.aboutUsSlide{
    height: 500px;
        width: 50% !important;
    .carousel{
       height: 470px;
       left:50%
    }
    .carousel-item img{
        top: 0px !important;
    }
}
$breakpoint-desktopfrom: 1007px;
$breakpoint-desktopTo: 1350px;
@media (min-width: $breakpoint-desktopfrom) and (max-width:$breakpoint-desktopTo) {
    
    
}

$breakpoint-mobile: 300px;
$breakpoint-mobileTo: 500px;
@media (min-width: $breakpoint-mobile) and (max-width:$breakpoint-mobileTo) {
    .aboutUsSlide{
        height: auto !important;
        width: 100% !important;
        .carousel-item{
            height: 60px!important;
            margin-top:150px !important;
            margin-bottom: 0 !important;
        }
        .carousel{
            height: 470px !important;
            left: 0px !important;
         }
         .carousel-item img{
             top: -150px !important;
         }
    div.carousel-inner>{
        height:200px!important;
    } 
   div.carousel.slide{
        height:200px !important;
        overflow: hidden;
    }
}
}

$breakpoint-mobile: 700px;
$breakpoint-mobileTo: 900px;
@media (min-width: $breakpoint-mobile) and (max-width:$breakpoint-mobileTo) {
    .aboutUsSlide{
        .carousel-item{
            //height: 600px!important;
            margin-top:150px !important;
            margin-bottom: 0 !important;
        }
    div.carousel-inner>{
        //height:600px!important;
    } 
   div.carousel.slide{
        //height:600px !important;
        overflow: hidden;
    }
}
}
